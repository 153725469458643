export const defaultTheme = {
    colors: {
      primary: "#1E2F23",
      gold: '#A88622',
      black: "#1e1e1e",
      gray: "#808080",
      white: "#fff",
      red: "#9B2915",
      btnGreen: "#0F4337"
    },
    breakpoints: {
      laptop_big: "1601px",
      laptop: "1471px",
      laptop_small: "1281px",
      tablet_big: "1080px",
      tablet: "867px",
      tablet_small: "769px",
      phone_big: "601px",
      phone: "431px",
      phone_small: "374px",
    },
  }
  