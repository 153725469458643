import React from "react"
import PropTypes from "prop-types"

import { ThemeProvider } from "styled-components"
import { defaultTheme } from "shared/Themes"

import "shared/Styles/global.css"
import "shared/Styles/reset.css"

import { GlobalWrapper } from "shared/Styles/Wrappers"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

const MainLayout = ({ children }) => { 
    return (
        <GoogleReCaptchaProvider reCaptchaKey="6LcSJ4gcAAAAAGA3eLOhiL39ZqVCCgnD93U4rS0X">
          <ThemeProvider theme={defaultTheme}>
              <GlobalWrapper>
                {children}
              </GlobalWrapper>
          </ThemeProvider>
        </GoogleReCaptchaProvider>
    )
}

MainLayout.propTypes = {
  children: PropTypes.node,
}

export default MainLayout
